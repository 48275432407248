.small{
   
    width: 100%;
    height: 600px;
    overflow: auto;

    
 
  }

  
/* width */
::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }



      /* Media Query for Mobile Devices */
      @media (max-width: 480px) {
        .position-fixed {
          position: fixed!important;
          width: 260.5px;
      }
      #head{
        font-size:'15px';
      }
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 767px) {
      .position-fixed {
        position: fixed!important;
        width: 699px;
    }
    #head{
      font-size:'25px';
    }
    }
      
    /* Media Query for Tablets Ipads portrait mode */
    @media (min-width: 768px) and (max-width: 1024px){
      .position-fixed {
        position: fixed!important;
        width: 699px;
    }
    #head{
      font-size:'35px';
    }
    }
      
    /* Media Query for Laptops and Desktops */
    @media (min-width: 1025px) and (max-width: 1280px){
      .position-fixed {
        position: fixed!important;
        width: 1113px;
    }
    #head{
      font-size:'45px';
    }
    }
      
    /* Media Query for Large screens */
    @media (min-width: 1281px) {
      .position-fixed {
        position: fixed!important;
        width: 1113px;
    }
    #head{
      font-size:'45px';
    }
    }
  